import * as React from "react"
import IClassProps from "../../interfaces/class-props"
import IPostPerson from "../../interfaces/post-person"

import BaseImage from "../base-image"

interface HeadShotProps extends IClassProps {
  person: IPostPerson
  image: any
  size?: number
  imgClassName?: string
}

export default function HeadShot({
  id,
  person,
  image,
  imgClassName,
  className,
}: HeadShotProps) {
  return (
    <BaseImage
      id={id}
      image={image}
      alt={person.frontmatter.name}
      className={className}
      imgClassName={imgClassName}
      loading="eager"
    />
  )
}

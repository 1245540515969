import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "../../lib/class-names"
import * as React from "react"
import BlueButtonLink from "../link/blue-button-link"
import SecondaryButtonLink from "../link/secondary-button-link"
import BlueLink from "../link/blue-link"
import VCenterRow from "../v-center-row"
import BlackLink from "../link/black-link"

type ContactInfoProps = {
  person?: any
  showPhone?: boolean
  className?: string
}

function ContactInfo({
  person,
  className,
  showPhone = true,
}: ContactInfoProps) {
  return (
    <ul className={cn("flex flex-col gap-y-3 lg:gap-x-4", className)}>
      {person.frontmatter.email !== "" && (
        <li>
          {/* <HCenterRow className="bg-button-selected-blue text-white min-w-12 h-12 items-center">
          <FontAwesomeIcon icon={faEnvelope} className="w-4" />
          </HCenterRow> */}
          <VCenterRow className="gap-3">
            <BlueButtonLink
              href={`mailto:${person.frontmatter.email}`}
              className="flex h-8 w-8 flex-row items-center"
              ariaLabel={`Email ${person.frontmatter.email}`}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </BlueButtonLink>

            <BlueLink
              href={`mailto:${person.frontmatter.email}`}
              className="text-sm"
              ariaLabel={`Email ${person.frontmatter.email}`}
            >
              {person.frontmatter.email}
            </BlueLink>
          </VCenterRow>
          {/* <BlueButtonLink
              href={`mailto:${person.frontmatter.email}`}
              ariaLabel={`Email ${person.frontmatter.email}`}
            >
              Contact Me
            </BlueButtonLink> */}
        </li>
      )}
      {showPhone && person.frontmatter.phone !== "" && (
        <li>
          <VCenterRow className="gap-3">
            <SecondaryButtonLink
              href={`tel:${person.frontmatter.phone}`}
              className="flex h-8 w-8 flex-row items-center"
              ariaLabel={`Call ${person.frontmatter.phone}`}
            >
              <FontAwesomeIcon icon={faPhone} />
            </SecondaryButtonLink>

            <BlackLink
              href={`tel:${person.frontmatter.phone}`}
              className="text-sm"
              ariaLabel={`Call ${person.frontmatter.phone}`}
            >
              {person.frontmatter.phone}
            </BlackLink>
          </VCenterRow>
          {/* <PhoneLink phone={person.frontmatter.phone} /> */}
        </li>
      )}

      {/* {person.frontmatter.profile && (<li>
                  <IndexLink
                    href={person.frontmatter.profile}
                    className="text-sm"
                    ariaLabel="View Columbia Profile"
                    underline={true}
                  >
                    Columbia Profile
                  </IndexLink></li>
                )} */}
    </ul>
  )
}

export default ContactInfo

// "use client"

import * as React from "react"
import { useEffect, useState } from "react"
//import Button from "../../components/button"
//import PublicationYears from "./publicationyears"
import BasePublicationList from "./base-publication-list"
import NoResults from "../no-results"
import BlueButton from "../link/blue-button"
import { TEXT_SHOW_MORE } from "../../constants"
import HCenterRow from "../h-center-row"

interface IRecentPublicationsProps {
  publications: any[]
  showAbstract?: boolean
  top?: number
  showCount?: boolean
  showMoreButton?: boolean
  className?: string
  onShowMoreClick?: any
  recordsPerPage?: any
}

function RecentPublications({
  publications,
  showAbstract,
  top = 10,
  showCount = false,
  showMoreButton = false,
  className,
  onShowMoreClick,
}: IRecentPublicationsProps) {
  const [filteredPublications, setFilteredPublications] = useState<any[]>([])
  const [recordsPerPage, setRecordsPerPage] = useState(-1)

  useEffect(() => {
    updatePublications()
  }, [])

  // useEffect(() => {
  //   if (recordsPerPage != top) {
  //     setRecordsPerPage(top)
  //   } else {
  //     updatePublications()
  //   }
  // }, [publications])

  useEffect(() => {
    updatePublications()
  }, [recordsPerPage])

  function updatePublications() {
    setFilteredPublications(publications.slice(0, recordsPerPage))
  }

  function handleShowMoreClick() {
    setRecordsPerPage(2 * recordsPerPage)
  }

  return (
    <>
      <div className={`${className}`}>
        {filteredPublications.length > 0 ? (
          <BasePublicationList
            publications={filteredPublications}
            showAbstract={showAbstract}
            showCount={showCount}
          />
        ) : (
          <NoResults text="No publications found." />
        )}
      </div>

      {recordsPerPage < publications.length && showMoreButton && (
        <HCenterRow className="mt-8">
          <BlueButton ariaLabel={TEXT_SHOW_MORE} onClick={onShowMoreClick}>
            {TEXT_SHOW_MORE}
          </BlueButton>
        </HCenterRow>
      )}
    </>
  )
}

export default RecentPublications

import cn from "../../lib/class-names"
import IPostPerson from "../../interfaces/post-person"
import BaseLink from "../link/base-link"
import * as React from "react"
//import SecondaryButtonLink from "../link/secondary-button-link"

// `https://pubmed.ncbi.nlm.nih.gov/?term=${person.frontmatter.lastName}+${person.frontmatter.firstName}%5BAuthor%5D&sort=pubdate`

function getLink(person: IPostPerson): string {
  const tokens = person.frontmatter.name.split(" ")
  const f = tokens[0]
  const l = tokens[tokens.length - 1]

  return `https://pubmed.ncbi.nlm.nih.gov/?term=(${l}+${f[0]}[Author])+AND+(Columbia+University[Affiliation])&sort=pubdate`
}

interface IPubMedLinkProps {
  person: IPostPerson
}

function PubMedLink({ person }: IPubMedLinkProps) {
  let url: string

  if (person.frontmatter.pubmed) {
    url = person.frontmatter.pubmed
  } else {
    url = getLink(person)
  }

  return (
    <BaseLink
      href={url}
      className={cn("flex flex-row items-center gap-x-3")}
      ariaLabel="View PubMed article"
    >
      <img
        src="/assets/svg/pubmed-logo-blue.svg"
        alt="View publications on PubMed"
        className="w-40 opacity-80 transition-opacity duration-200 hover:opacity-100"
        width="249"
        height="69"
      />
      {/* <FontAwesomeIcon icon={faChevronRight} /> */}
    </BaseLink>
  )
}

export default PubMedLink

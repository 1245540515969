import cn from "../lib/class-names"
import HTML, { IHtmlProps } from "./html"
import * as React from "react"

interface IMarkdownProps extends IHtmlProps {
  color?: string
}

function MarkdownBody({
  html,
  color = "markdown-default",
  className,
}: IMarkdownProps) {
  return <HTML html={html} className={cn("markdown", color, className)} />
}

export default MarkdownBody

//import axios from "axios"
import { graphql } from "gatsby"
import * as React from "react"

import ArticleDiv from "../components/article-div"
import BaseCol from "../components/base-col"
import BaseRow from "../components/base-row"
import MarkdownBody from "../components/markdown-body"
import PersonHeaderHoz from "../components/people/person-header-hoz"

import PubMedLink from "../components/publication/pubmed-link"
import RecentPublications from "../components/publication/recent-publications"
import QuoteStart from "../components/icons/quote-start"

import Seo from "../layouts/seo"

import sortPublications from "../lib/sort-publications"
import getTitleMap from "../lib/title-map"
import IDataPageProps from "../interfaces/data-page-props"

import BaseLayout from "../layouts/base-layout"
import VCenterCol from "../components/v-center-col"
import ContactInfo from "../components/people/contact-info"
import BlueIndexLink from "../components/link/blue-index-link"
import SeventyLayout from "../layouts/seventy-layout"
import PersonHeaderImage from "../components/people/person-header-image"

export default function Page({ data, location }: IDataPageProps) {
  const person = data.person
  const research = data.research

  let publications

  if (data.publications) {
    publications = sortPublications(data.publications.publications)
  } else {
    const names = person.frontmatter.name.split(" ")

    const initials = names[0][0]
    const surname = names[names.length - 1]
    const author = `${surname} ${initials}`

    const pubs = data.allPublications.publications.filter(
      (pub: { authors: string }) => pub.authors.includes(author)
    )

    publications = sortPublications(pubs)
  }

  const image = data.image //? data.image : data.genericImage

  person.titleMap = getTitleMap(person.frontmatter.titles)

  return (
    <BaseLayout
      title={person.frontmatter.name}
      tab="People"
      location={location}
      showTitle={false}
    >
      <ArticleDiv className="py-16">
        <></>
        <>
          <SeventyLayout className="gap-y-8 2xl:gap-x-16" isRight={true}>
            <PersonHeaderHoz person={person} image={image} />
            <></>
          </SeventyLayout>

          <SeventyLayout className="mt-16 gap-y-8 2xl:gap-x-12" isRight={true}>
            <>
              <VCenterCol className="mb-16 gap-y-4 rounded-2xl border border-gray-200 p-6 2xl:hidden">
                {/* <h1 className="font-medium">Get In Touch</h1> */}

                <ContactInfo person={person} />

                {person.frontmatter.profile && (
                  <BlueIndexLink
                    href={person.frontmatter.profile}
                    className="mt-2 text-sm"
                    ariaLabel="View Columbia Profile"
                    underline={true}
                  >
                    View Columbia Profile
                  </BlueIndexLink>
                )}
              </VCenterCol>

              <PersonHeaderImage data={data} location={location} />

              {research && (
                <BaseCol className="mt-8 gap-y-8 rounded-2xl bg-apple-gray p-8 lg:mt-16 lg:p-12">
                  <QuoteStart className="w-12 fill-gray-900/20" />
                  <MarkdownBody html={research.html} />
                  <BaseRow className="w-full justify-end">
                    <QuoteStart className="w-12 rotate-180 fill-gray-900/20" />
                  </BaseRow>
                </BaseCol>
              )}

              {!person.frontmatter.groups.lab.includes("Admin") && (
                <div className="mt-16 bg-white">
                  <h1 className="text-2xl font-medium">
                    Selected Publications
                  </h1>
                  <RecentPublications
                    publications={publications}
                    showAbstract={false}
                    showMoreButton={false}
                    showCount={true}
                    className="mt-8"
                  />

                  <BaseCol className="mt-8 items-center gap-y-2">
                    <span className="text-sm">See more on</span>
                    <PubMedLink person={person} />
                  </BaseCol>
                </div>
              )}
            </>

            <VCenterCol className="sticky top-24 gap-y-4 rounded-2xl border border-gray-200 p-6">
              {/* <h1 className="font-medium">Get In Touch</h1> */}

              <ContactInfo person={person} />

              {person.frontmatter.profile && (
                <BlueIndexLink
                  href={person.frontmatter.profile}
                  className="mt-2 text-sm"
                  ariaLabel="View Columbia Profile"
                  underline={true}
                >
                  View Columbia Profile
                </BlueIndexLink>
              )}
            </VCenterCol>
          </SeventyLayout>
        </>
        <></>
      </ArticleDiv>
    </BaseLayout>
  )
}

export function Head({ location, pageContext }: IDataPageProps) {
  return <Seo title={pageContext.name} />
}

export const pageQuery = graphql`
  query PersonPageQuery($personId: String!) {
    person: markdownRemark(
      fileAbsolutePath: { regex: "/people/" }
      frontmatter: { personId: { eq: $personId } }
    ) {
      id
      frontmatter {
        personId
        name
        postNominalLetters
        email
        phone
        titles
        groups {
          lab
          admin
        }
        labs
        profile
        caption
        pubmed
      }
    }

    research: markdownRemark(
      fileAbsolutePath: { regex: "/research/" }
      frontmatter: { personId: { eq: $personId } }
    ) {
      id
      html
    }

    publications: publicationsJson(personId: { eq: $personId }) {
      personId
      publications {
        title
        journal
        year
        doi
        pmid
        pmcid
        abstract
        authorList
      }
    }

    allPublications: publicationsJson(personId: { eq: "all" }) {
      personId
      publications {
        title
        journal
        year
        doi
        pmid
        pmcid
        abstract
        authors
        authorList
      }
    }

    image: file(
      absolutePath: { regex: "/images/people/600x600/" }
      name: { eq: $personId }
      ext: { eq: ".webp" }
    ) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, aspectRatio: 1)
      }
    }

    genericImage: file(
      absolutePath: { regex: "/images/people/600x600/generic.webp/" }
    ) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, aspectRatio: 1)
      }
    }

    headerImage: file(
      absolutePath: { regex: "/images/header/" }
      name: { eq: $personId }
      ext: { eq: ".webp" }
    ) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    genericHeaderImage: file(
      absolutePath: { regex: "/images/header/generic.webp/" }
    ) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    officeHeaderImage: file(
      absolutePath: { regex: "/images/header/office.webp/" }
    ) {
      name
      ext
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`

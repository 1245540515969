// "use client"

import * as React from "react"

import BaseCol from "../base-col"

import getContextName from "../../lib/context-name"

import sortPublications from "../../lib/sort-publications"
import getTitleMap from "../../lib/title-map"
import IDataPageProps from "../../interfaces/data-page-props"

import BaseImage from "../base-image"
import { useState } from "react"
import cn from "../../lib/class-names"

export default function PersonHeaderImage({ data }: IDataPageProps) {
  const [hover, setHover] = useState(false)

  function onMouseEnter() {
    setHover(true)
  }

  function onMouseLeave() {
    setHover(false)
  }

  const person = data.person

  const headerImage = data.headerImage
    ? data.headerImage
    : person.frontmatter.groups.lab.includes("Admin")
    ? data.officeHeaderImage
    : data.genericHeaderImage

  return (
    <div
      className="z-0 grid h-120 w-full overflow-hidden rounded-2xl border"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <BaseImage
        image={headerImage}
        alt="Header Image"
        loading="eager"
        className="z-0 h-full w-full"
        style={{
          gridArea: "1/1",
        }}
      />

      {person.frontmatter.caption && (
        <BaseCol
          className="z-10 h-full justify-end"
          style={{
            gridArea: "1/1",
          }}
        >
          <div
            className={cn(
              "z-10 bg-black/50 p-4 text-white backdrop-blur transition-opacity duration-500",
              [hover, "opacity-100", "opacity-0"]
            )}
          >
            {person.frontmatter.caption}
          </div>
        </BaseCol>
      )}
    </div>
  )
}

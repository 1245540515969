import IClassProps from "../interfaces/class-props"
import * as React from "react"

export interface IHtmlProps extends IClassProps {
  html: string
}

function HTML({ html, className }: IHtmlProps) {
  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
  )
}

export default HTML
